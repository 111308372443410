// Users
export const users = (state) => {
  return state.users;
};

export const user = (state) => {
  return state.user;
};

// Permits
export const permits = (state) => {
  return state.permits;
};

export const permit = (state) => {
  return state.permit;
};

export const permitsFilter = (state) => {
  return state.permitsFilter;
};

export const permitContacts = (state) => {
  return state.permitContacts;
};

export const permitFollowUps = (state) => {
  return state.permitFollowUps;
};

// Promoters
export const promoters = (state) => {
  return state.promoters;
};

export const promoter = (state) => {
  return state.promoter;
};

export const promoterContacts = (state) => {
  return state.promoterContacts;
};

export const promoterAgencies = (state) => {
  return state.promoterAgencies;
};

export const promoterPermits = (state) => {
  return state.promoterPermits;
};

// Agencies
export const agencies = (state) => {
  return state.agencies;
};

export const agency = (state) => {
  return state.agency;
};

export const agencyContacts = (state) => {
  return state.agencyContacts;
};

export const agencyPermits = (state) => {
  return state.agencyPermits;
};

// Cites
export const cities = (state) => {
  return state.cities;
};

export const city = (state) => {
  return state.city;
};

export const citiesFilter = (state) => {
  return state.citiesFilter;
};

export const cityPositions = (state) => {
  return state.cityPositions;
};

export const cityContacts = (state) => {
  return state.cityContacts;
};

export const cityCallbackDates = (state) => {
  return state.cityCallbackDates;
};

export const citiesContacts = (state) => {
  return state.citiesContacts;
};

export const cityComments = (state) => {
  return state.cityComments;
};

export const citiesCallbackDates = (state) => {
  return state.citiesCallbackDates;
};

// Addresses
export const addresses = (state) => {
  return state.addresses;
};

export const address = (state) => {
  return state.address;
};

// Contacts
export const contacts = (state) => {
  return state.contacts;
};

export const contact = (state) => {
  return state.contact;
};

export const contactCallbackDates = (state) => {
  return state.contactCallbackDates;
};

export const contactPermits = (state) => {
  return state.contactPermits;
};

// Call back dates
export const callBackDates = (state) => {
  return state.callBackDates;
};

export const callBackDatesNegotiation = (state) => {
  return state.callBackDatesNegotiation;
};

export const callBackDatesFavoris = (state) => {
  return state.callBackDatesFavoris;
};

export const callBackDatesLeft = (state) => {
  return state.callBackDatesLeft;
};

export const userAuth = (state) => {
  return state.userAuth;
};

export const message = (state) => {
  return state.message;
};

// Menu
export const menuOn = (state) => {
  return state.menuOn;
};
